<style>
.table thead th {
	position: sticky;
	top: 0;
	background-color: white;
	/* 確保表頭在滾動時不透明 */
	z-index: 10;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
	/* 可選：添加陰影增強效果 */
}
</style>

<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="d-flex align-items-center">

					案場代號：<input class="form-control" type="text" v-model="filter.houseCode"
						style="width:120px;margin-left: 5px;">
					逆變器編號：<input class="form-control" type="text" v-model="filter.inverterId"
						style="width:120px;margin-left: 5px;">
					<label htmlFor="dt1" class="col-sm-2 col-form-label">日期：</label>

					<input v-model="filter.dt1" type="date" class="form-control" id="dt1" name="dt1"
						style="width:140px;" />
					~
					<input v-model="filter.dt2" type="date" class="form-control" id="dt2" name="dt2"
						style="width:140px;" />

					<select class="form-select form-control" style="width: auto;margin-left: 10px;"
						v-model="filter.status">
						<option value="正常">正常</option>
						<option value="停機">停機</option>
						<option value="斷線">斷線</option>
						<option value="斷線">異常</option>
					</select>



					<button @click="fetchList()" class="btn btn-secondary" style="margin-left: 5px;">搜尋</button>


				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
											<span :title="header.tip" v-if="header.tip != ''"
												class="fa fa-question-circle"></span>
										</a>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.id">
									<td>{{ item.housecode }}</td>
									<td>{{ item.customer }}</td>
									<td>{{ item.inverter_id }}</td>
									<td>{{ item.R_ACv }}</td>
									<td>{{ item.R_ACi }}</td>
									<td>{{ item.PV1DCv }}</td>
									<td>{{ item.PV1DCi }}</td>
									<td>{{ item.PV2DCv }}</td>
									<td>{{ item.PV2DCi }}</td>
									<td>{{ item.current_pw }}</td>
									<td>{{ item.day_pw }}</td>
									<td>{{ item.boardspower }}</td>
									<td>{{ findBoard(item.housecode,item.inverter_id).amount }}</td>
									<td>{{ Math.round((item.current_pw / item.boardspower) * 100) / 100 }}</td>
									<td>{{ item.sum_pw }}</td>
									<td>{{ item.status }}</td>
									<td>{{ item.dt1 }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" @pageSizeChange="onPageSizeChange" />

			</div>
		</div>
	</layout-div>
	<div class="modal fade" id="filterModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">搜尋</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="card">
						<form asp-action="Index" id="searchForm">
							<div class="card-body row">
								<div class="col-auto">
									<label class="control-label">客戶</label>
									<input v-model="filter.customer" id="customer" class="form-control" />
								</div>
								<div class="col-auto">
									<label class="control-label">案場代號</label>
									<input v-model="filter.housecode" id="housecode" class="form-control" />
								</div>

							</div>
						</form>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="reset()" class="btn btn-primary">清除</button>
					<button type="button" @click="fetchList()" class="btn btn-primary">開始搜尋</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listPowerCheckData } from '@/api/Power.js'
import { withBoard } from '@/api/Inverter.js'

export default {
	name: 'Statuslist',
	extends: baseList,
	data() {
		/*
		估值   每日平均發電 /  面板KW
		*/
		return {
			filter: {
				status: "正常",
				houseCode: "",
				dt1: "",
				dt2: "",
				inverterId: "",

			},
			pagination: {
				sortItem: "id",
				sortDir: "ASC",
				page: 1,
				pageSize: 10
			},
			headers: [
				{ id: 'housecode', label: "案場代號", icon: "", tip: "b" },
				{ id: 'customer', label: "客戶", icon: "", tip: "等於A+B" },
				{ id: 'inverter_id', label: "逆變器位址", icon: "", tip: "c" },
				{ id: 'R_ACv', label: "R相交流V", icon: "", tip: "c" },
				{ id: 'R_ACi', label: "R相交流I", icon: "", tip: "c" },
				{ id: 'PV1DCv', label: "PV1直流V", icon: "", tip: "c" },
				{ id: 'PV1DCi', label: "PV1直流I", icon: "", tip: "c" },
				{ id: 'PV2DCv', label: "PV2直流V", icon: "", tip: "c" },
				{ id: 'PV2DCi', label: "PV2直流I", icon: "", tip: "c" },
				{ id: 'current_pw', label: "目前輸出功率(W) ", icon: "", tip: "" },
				{ id: 'day_pw', label: "當日發電度數(KW/H)", icon: "", tip: "" },
				{ id: 'boardspower', label: "面板組KW數", icon: "", tip: "" },
				{ id: 'boardcount', label: "面板數量", icon: "", tip: "" },
				{ id: 'effic', label: "每K效率", icon: "", tip: "" },
				{ id: 'sum_pw', label: "累積發電度數(KW)", icon: "", tip: "" },
				{ id: 'status', label: "逆變器狀態", icon: "", tip: "" },
				{ id: 'dt1', label: "時間", icon: "", tip: "" },
			],
			model: [],
			withBoard: [],
			total: 0,
			filterTotal: 0

		};
	},
	computed: {
		filteredHeaders() {
			// Filter headers based on showAll and hideColumn
			if (this.showAll) {
				return this.headers;
			}
			return this.headers.filter(header => !this.hideColumn.includes(header.id));
		},
		filteredModel() {
			// For each item in model, include only fields in filteredHeaders
			return this.model.map(item => {
				const filteredItem = {};
				this.filteredHeaders.forEach(header => {
					filteredItem[header.id] = item[header.id];
				});
				return filteredItem;
			});
		},
	},
	mounted() {
		this.fetchWithBoard();
	},
	methods: {
		findBoard(houseCode, inverterId) {
			return this.withBoard.find(
				(item) =>
					item.housecode === houseCode &&
					item.inverter_id === inverterId
			);
		},
		reset() {
			this.filter.housecode = "";

		},
		getRequest() {
			var result =
			{
				"status": this.filter.status,
				"inverterId": this.filter.inverterId,
				"houseCode": this.filter.houseCode,
				"dt1": this.filter.dt1,
				"dt2": this.filter.dt2,
			};

			return result;
		},
		fetchList() {
			listPowerCheckData(this.getRequest()).then(rep => {
				//console.log(rep);
				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc;

			});
		},
		fetchWithBoard() {
			withBoard(this.getRequest()).then(rep => {
				//console.log(rep);
				let sc = rep.data;
				this.withBoard = sc;
			});
		},

		showfilterForm() {
			$("#filterModal").modal('show');
		},
		onPageSizeChange(newPageSize) {
			this.pagination.pageSize = newPageSize;
			this.fetchList();
		},
	},
};
</script>
